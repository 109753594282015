import React from "react"
import MainNav from "../components/mainnav"

const NotFoundPage = () => (
  <div>
    <h1>NOT FOUND</h1>
    <p>
      You just hit a route that doesn&#39;t exist. Please click on one of the
      links below
    </p>
    <MainNav />
  </div>
)

export default NotFoundPage
